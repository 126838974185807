@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  /*overflow: hidden;*/
  /*height: 100%;*/
  @apply bg-black
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply font-black
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

h1 {
  @apply text-xl text-white
}

h2 {
  @apply text-lg text-white opacity-50
}
