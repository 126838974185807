.App {
    /*display: flex;*/
    /*height: 100% ;*/
    /*position: absolute;*/
    /*width: 100% ;*/
    /*overflow: hidden;*/
}

/*.card {*/
/*    width: 30vh;*/
/*    height: 40vh;*/
/*    border-radius: 30px;*/
/*}*/

.mask {
    mask-image: url('./assets/images/iphone-14-pro-mask.svg');
}
